<template>
    <section class="invoice-preview-wrapper">

        <!-- Alert: No item found -->
        <b-alert
            variant="danger"
            :show="invoiceData === undefined"
        >
            <h4 class="alert-heading">
                {{i18nT(`Error fetching invoice data`)}}
            </h4>
            <div class="alert-body">
                {{i18nT(`No invoice found with this invoice id. Please check the invoice id and try again.`)}}
                <b-link
                    class="alert-link"
                    :to="{ name: 'quotes'}"
                >
                    Invoice List
                </b-link>
                for other invoices.
            </div>
        </b-alert>

        <b-row
            v-if="invoiceData && false"
            class="invoice-preview"
        >

            <!-- Col: Left (Invoice Container) -->
            <b-col
                cols="12"
                xl="9"
                md="8"
            >
                <b-card
                    no-body
                    class="invoice-preview-card"
                >
                    <!-- Header -->
                    <b-card-body class="invoice-padding pb-0">

                        <div class="d-flex justify-content-between flex-md-row flex-column invoice-spacing mt-0">

                            <!-- Header: Left Content -->
                            <div>
                                <div class="logo-wrapper" v-if="invoiceData.WorkspaceObj">
                                    <b-card-img :src="invoiceData.WorkspaceObj.LogoUrl" alt="Card image"></b-card-img>
                                    <h3 class="text-primary invoice-logo">
                                        {{ invoiceData.WorkspaceObj.Label }}
                                    </h3>
                                </div>
                                <p class="card-text mb-25">
                                    {{ invoiceData.WorkspaceObj.Vat }}
                                </p>
                                <p class="card-text mb-25">
                                    {{ invoiceData.WorkspaceObj.Address }}
                                </p>
                                <p class="card-text mb-25">
                                    {{ invoiceData.WorkspaceObj.City }}, {{ invoiceData.WorkspaceObj.State }}, {{ invoiceData.WorkspaceObj.Zip }}, {{ invoiceData.WorkspaceObj.Country }}
                                </p>
                                <p class="card-text mb-0">
                                    {{ invoiceData.WorkspaceObj.BusinessPhone }}, {{ invoiceData.WorkspaceObj.BusinessEmail }}
                                </p>
                            </div>

                            <!-- Header: Right Content -->
                            <div class="mt-md-0 mt-2">
                                <h4 class="invoice-title">
                                    {{i18nT(`Invoice`)}}:
                                    <span class="invoice-number">#{{ invoiceData.InvoiceNumber }}</span>
                                </h4>
                                <div class="invoice-date-wrapper">
                                    <p class="invoice-date-title">
                                        {{i18nT(`Date Issued`)}}:
                                    </p>
                                    <p class="invoice-date">
                                        {{ invoiceData.InvoiceDate | date }}
                                    </p>
                                </div>
                                <div class="invoice-date-wrapper">
                                    <p class="invoice-date-title">
                                        {{i18nT(`Due Date`)}}:
                                    </p>
                                    <p class="invoice-date">
                                        {{ invoiceData.DueDate | date }}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </b-card-body>

                    <!-- Spacer -->
                    <hr class="invoice-spacing">

                    <!-- Invoice Client & Payment Details -->
                    <b-card-body
                        v-if="invoiceData.VendorObj"
                        class="invoice-padding pt-0"
                    >
                        <b-row class="invoice-spacing">

                            <!-- Col: Invoice To -->
                            <b-col
                                cols="12"
                                xl="6"
                                class="p-0"
                            >
                                <h6 class="mb-2">
                                    {{i18nT(`Invoice To`)}}:
                                </h6>
                                <h6 class="mb-25">
                                    {{ invoiceData.VendorObj.Label }}
                                </h6>
                                <p class="card-text mb-25">
                                    {{ invoiceData.VendorObj.company }}
                                </p>
                                <p class="card-text mb-25">
                                    {{ invoiceData.VendorObj.Address }}, {{ invoiceData.VendorObj.City }}
                                </p>
                                <p class="card-text mb-25">
                                    {{ invoiceData.VendorObj.Zip }}, {{ invoiceData.VendorObj.Country }}
                                </p>
                                <p class="card-text mb-25">
                                    {{ invoiceData.VendorObj.contact }}
                                </p>
                                <p class="card-text mb-0">
                                    {{ invoiceData.VendorObj.companyEmail }}
                                </p>
                            </b-col>
                        </b-row>
                    </b-card-body>

                    <!-- Invoice Description: Table -->
                    <b-table-lite
                        responsive
                        :items="invoiceData.InvoiceItems"
                        :fields="['taskDescription', 'unitPrice', 'units', 'vat', 'total']"
                    >
                        <template #cell(taskDescription)="data">
                            <b-card-text class="font-weight-bold mb-25">
                                {{ data.item.Label }}
                            </b-card-text>
                            <b-card-text class="text-nowrap">
                                {{ data.item.LabelOther }}
                            </b-card-text>
                        </template>

                        <template #cell(unitPrice)="data">
                            {{ data.item.UnitPrice }}
                        </template>

                        <template #cell(units)="data">
                            {{ data.item.Quantity }}
                        </template>

                        <template #cell(total)="data">
                            {{ data.item.TotalAmount }}
                        </template>

                    </b-table-lite>

                    <!-- Invoice Description: Total -->
                    <b-card-body class="invoice-padding pb-0">
                        <b-row>

                            <!-- Col: Sales Persion -->
                            <b-col
                                cols="12"
                                md="6"
                                class="mt-md-0 mt-3"
                                order="2"
                                order-md="1"
                            >
                                <b-card-text class="mb-0">
                                    <span class="font-weight-bold">Salesperson:</span>
                                    <span class="ml-75">Alfie Solomons</span>
                                </b-card-text>
                            </b-col>

                            <!-- Col: Total -->
                            <b-col
                                cols="12"
                                md="6"
                                class="mt-md-6 d-flex justify-content-end"
                                order="1"
                                order-md="2"
                            >
                                <div class="invoice-total-wrapper">
                                    <div class="invoice-total-item">
                                        <p class="invoice-total-title">
                                            {{i18nT(`Subtotal`)}}:
                                        </p>
                                        <p class="invoice-total-amount">
                                            {{invoiceData.Currency}}  {{ invoiceData.TotalAmount }}
                                        </p>
                                    </div>
                                    <div class="invoice-total-item" v-if="invoiceData.DiscountAmount">
                                        <p class="invoice-total-title">
                                            {{i18nT(`Discount`)}}:
                                        </p>
                                        <p class="invoice-total-amount" >
                                            {{invoiceData.Currency}} {{invoiceData.DiscountAmount}}
                                        </p>
                                    </div>
                                    <div class="invoice-total-item">
                                        <p class="invoice-total-title">
                                            {{i18nT(`VAT`)}}:
                                        </p>
                                        <p class="invoice-total-amount">
                                            {{invoiceData.VatAmount}}%
                                        </p>
                                    </div>
                                    <hr class="my-50">
                                    <div class="invoice-total-item">
                                        <p class="invoice-total-title">
                                            Total:
                                        </p>
                                        <p class="invoice-total-amount">
                                            {{invoiceData.Currency}} {{ invoiceData.TotalAmount }}
                                        </p>
                                    </div>
                                </div>
                            </b-col>
                        </b-row>
                    </b-card-body>

                    <!-- Spacer -->
                    <hr class="invoice-spacing">

                    <!-- Note -->
                    <b-card-body class="invoice-padding pt-0">
                        <span class="font-weight-bold">{{i18nT(`Note`)}}: </span>
                        <span>{{invoiceData.Description}}</span>
                    </b-card-body>
                </b-card>
            </b-col>

            <!-- Right Col: Card -->
            <b-col
                cols="12"
                md="4"
                xl="3"
                class="invoice-actions"
            >
                <b-card>

                    <!-- Button: Send Quote -->
                    <b-button
                        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                        v-b-toggle.sidebar-send-invoice
                        variant="primary"
                        class="mb-75"
                        block
                    >
                        {{i18nT(`Send Quote`)}}
                    </b-button>

                    <!-- Button: Download -->
                    <b-button
                        v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                        variant="outline-secondary"
                        class="mb-75"
                        block
                    >
                        {{i18nT(`Download`)}}
                    </b-button>

                    <!-- Button: Create invoice -->
                    <b-button
                        v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                        variant="outline-secondary"
                        class="mb-75"
                        block
                        @click="onCreateInvoice"
                    >
                        {{i18nT(`Create Invoice`)}}
                    </b-button>

                    <!-- Button: Edit -->
                    <b-button
                        v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                        variant="outline-secondary"
                        class="mb-75"
                        block
                        :to="{ name: 'quote-edit', params: { id: $route.params.id } }"
                    >
                        {{i18nT(`Edit`)}}
                    </b-button>

                </b-card>
            </b-col>
        </b-row>

        <b-row class="frame-row">
            <b-col
                cols="12"
                xl="9"
                md="8"
                id="frame-holder"
            >
                <iframe id="print-frame" :src="invoiceData.IframePublicUrl+`&lang=`+locale" width="100%" height="1000px" frameborder="0"></iframe>
            </b-col>
            <!-- Right Col: Card -->
            <b-col
                cols="12"
                md="4"
                xl="3"
                class="invoice-actions"
            >
                <b-card>
                    <div v-if="getStatusTitle" class="status-holder pb-2 text-center">
                        <span class="status-title mr-05">
                            {{ i18nT(`Status`) }}:
                        </span>
                        <span
                            :class="statusBadgeClasses()">
                          {{ getStatusTitle }}
                      </span>
                        <div class="mt-2">
                            <div v-if="SentAt" class="font-small-3">
                                <strong>{{ i18nT(`Sent on`) }}: </strong><span>{{ SentAt | date }}</span>
                            </div>
                            <div v-if="SentTo" class="font-small-3">
                                <strong>{{ i18nT(`Sent to`) }}: </strong><span>{{ SentTo }}</span>
                            </div>
                        </div>
                    </div>

                    <!-- Button: Send Invoice -->
                    <b-button
                        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                        v-b-toggle.sidebar-send-invoice
                        variant="primary"
                        class="mb-75"
                        block
                    >
                        <font-awesome-icon icon="paper-plane" class="mr-1" />
                        {{i18nT(`Send Quote`)}}

                    </b-button>

                    <!-- Button: Download -->
                    <b-button
                        v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                        variant="outline-secondary"
                        class="mb-75"
                        block
                        @click="openDownload"
                        :disabled="!invoiceData.QuoteDownloadUrl"
                    >
                        <font-awesome-icon icon="download" class="mr-1" />
                        {{i18nT(`Download`)}}
                    </b-button>

                    <!-- Button: Print -->
                    <b-button
                        v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                        variant="outline-secondary"
                        class="mb-75"
                        block
                        @click="onCreateInvoice"
                    >
                        <font-awesome-icon  :icon="['fas', 'file-invoice-dollar']" class="mr-1" />
                        {{i18nT(`Create invoice`)}}
                    </b-button>

                    <b-button
                        v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                        variant="outline-secondary"
                        class="mb-75"
                        block
                        @click="showPublicLink"
                    >

                        <font-awesome-icon icon="link" class="mr-1" />
                        {{i18nT(`Open public link`)}}
                    </b-button>


                    <!-- Button: Edit -->
                    <b-button
                        v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                        variant="outline-primary"
                        class="mb-75"
                        block
                        :to="{ name: 'quote-edit', params: { id: $route.params.id } }"
                    >
                        <font-awesome-icon icon="pen" class="" />
                        {{i18nT(`Edit`)}}
                    </b-button>

                </b-card>
            </b-col>

        </b-row>

        <quote-sidebar-send />
    </section>
</template>

<script>
//import router from '@/router'
import {
    BRow, BCol, BCard, BCardBody, BTableLite, BCardText, BButton, BAlert, BLink, VBToggle,
} from 'bootstrap-vue'

import Ripple from 'vue-ripple-directive'
import store from '@/store'
import QuoteSidebarSend from '@/views/accounting/Quotes/QuoteSidebarSend'
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
    directives: {
        Ripple,
        'b-toggle': VBToggle,
    },
    components: {
        BRow,
        BCol,
        BCard,
        BCardBody,
        BTableLite,
        BCardText,
        BButton,
        BAlert,
        BLink,
        QuoteSidebarSend,
    },
    data() {
        return {
            invoiceDescription: [],
            locale: this.$i18n.locale,
        }
    },

    methods: {
        onCreateInvoice(){
            let formData = new FormData();
            formData.append('quote_id', this.invoiceData.Id);

            this.$http.post(`quotes/createInvoice`, formData).then((response) => {
                this.$toast({
                    component: ToastificationContent,
                    props: {
                        title: this.i18nT(`Success`),
                        icon: 'CheckIcon',
                        variant: 'success',
                        message: this.i18nT(`Invoice created successfully`)
                    },
                });
                this.$router.push({name: 'edit-invoice', params: {id: response.data.data.Id}});
            });
        },

        statusBadgeClasses() {
            let base_classes = 'badge badge-pill ';
            switch (this.invoiceData.Status) {
                case 'pending':
                    return base_classes + 'badge-light-warning';
                case 'accepted':
                    return base_classes + 'badge-light-success';
                case 'rejected':
                    return base_classes + 'badge-light-danger';
                default:
                    return base_classes + 'badge-light-secondary';
            }
        },
        printInvoice() {
            window.print()
        },
        openDownload() {
            window.open(this.invoiceData.QuoteDownloadUrl, '_blank');
        },
        showPublicLink() {
            window.open('/quote/'+this.invoiceData.Id+'/h/'+this.invoiceData.Hash, '_blank');
        }
    },
    mounted() {
        let enumsReady = store.getters['invoices/getEnumsState'];

        if(enumsReady){
            store.dispatch('invoices/populateEnums');
        }

        store.dispatch('invoices/fetchQuoteDetails', this.$route.params.id);
    },
    computed: {
        getStatusTitle() {
            if (this.invoiceData && this.invoiceData.Status) {
                let status = this.invoiceData.Status;
                return this.i18nT(status.charAt(0).toUpperCase() + status.slice(1)).replace('_', ' ');
            } else {
                return null;
            }
        },
        SentAt() {
            if (this.invoiceData && this.invoiceData.SentAt) {
                return this.invoiceData.SentAt;
            } else {
                return null;
            }
        },
        SentTo() {
            if (this.invoiceData && this.invoiceData.SentTo) {
                return this.invoiceData.SentTo;
            } else {
                return null;
            }
        },
        invoiceEnums () {
            return store.getters['invoices/getInvoiceEnums'];
        },
        curInvoiceId(){
            return this.$route.params.id;
        },
        invoiceData(){
            return store.getters['invoices/getQuoteData'];
        }
    },
    watch: {
        curInvoiceId (val, oldVal) {
            if(val && val !== oldVal)
                store.dispatch('invoices/fetchQuoteDetails', val);
        }
    }

}
</script>

<style lang="scss" scoped>
@import "~@core/scss/base/pages/app-invoice.scss";
</style>

<style lang="scss">
.status-holder {
    font-size: 14px;
    text-align: center;
}


@media print {

    // Global Styles
    body {
        background-color: transparent !important;
    }
    nav.header-navbar {
        display: none;
    }
    .main-menu {
        display: none;
    }
    .header-navbar-shadow {
        display: none !important;
    }
    .content.app-content {
        margin-left: 0;
        padding-top: 2rem !important;
    }
    footer.footer {
        display: none;
    }
    .card {
        background-color: transparent;
        box-shadow: none;
    }
    .customizer-toggle {
        display: none !important;
    }

    // Action Right Col
    .invoice-actions,
    .b-sidebar-outer,
    .footer,
    .crisp-client,
    .Vue-Toastification__container,
    .btn-scroll-to-top,
    .vertical-layout .card.suggestion-box,
    .vertical-layout .breadcrumb-wrapper,
    .content-overlay,
    .content-wrapper row:not(.frame-row){
        display: none !important;
    }

    #frame-holder {
        width: 100vw !important;
        min-width: 820px;
        display: flex;
        justify-content: center;
        align-items: center;
        vertical-align: top;
    }

    iframe#print-frame{
        width: 100% !important;
        min-width: 800px;
    }

    // Invoice Specific Styles
    .invoice-preview-wrapper {
        //.row.invoice-preview {
        //  .col-md-8 {
        //    max-width: 100%;
        //    flex-grow: 1;
        //  }
        //
        //  .invoice-preview-card {
        //    .card-body:nth-of-type(2) {
        //      .row {
        //          > .col-12 {
        //          max-width: 50% !important;
        //        }
        //
        //        .col-12:nth-child(2) {
        //          display: flex;
        //          align-items: flex-start;
        //          justify-content: flex-end;
        //          margin-top: 0 !important;
        //        }
        //      }
        //    }
        //  }
        //}


    }
}
</style>
